import axios from '@axios'
import common from '@/libs/common'
import defaultService from './_default.service'

export default function customerService() {
  const defaultMethods = defaultService('Clientes')
  const { throwError, throwSuccess } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  // General ----------------------------------------
  const fetchCustomers = (data, callback) => {
    axios
      .get(`${API_URL}/Clientes`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createCustomer = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Clientes`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const fetchCustomer = ({ idCustomer }, callback) => {
    axios
      .get(`${API_URL}/Clientes/${idCustomer}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateCustomer = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/Clientes/${data.clienteId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteCustomer = data => new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}/Clientes/${data.idCustomer}`)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  // Related Customer -------------------------------
  const fetchRelatedCustomers = (idCustomer, callback) => {
    axios
      .get(`${API_URL}/Clientes/${idCustomer}/ClienteRelacionado`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createRelatedCustomer = (data, callback) => {
    axios
      .post(`${API_URL}/Clientes/${data.clienteId}/ClienteRelacionado`, data)
      .then(response => {
        throwSuccess(response)
        callback()
      })
      .catch(error => throwError(error))
  }
  const fetchRelatedCustomer = ({ idCustomer, idRelatedCustomer }, callback) => {
    axios
      .get(`${API_URL}/Clientes/${idCustomer}/ClienteRelacionado/${idRelatedCustomer}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateRelatedCustomer = (data, callback) => {
    axios
      .put(`${API_URL}/Clientes/${data.clienteId}/ClienteRelacionado/${data.clienteRelacionadoId}`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const deleteRelatedCustomer = ({ idCustomer, idRelatedCustomer }, callback) => {
    axios
      .delete(`${API_URL}/Clientes/${idCustomer}/ClienteRelacionado/${idRelatedCustomer}`)
      .then(response => {
        throwSuccess(response)
        callback()
      })
      .catch(error => throwError(error))
  }
  // Receiver Customer
  const createCustomerRecipients = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Clientes/DestinatarioExpedienteDigital`, data)
      .then(response => {
        throwSuccess(response)
        resolve(response)
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const updateCustomerRecipient = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/Clientes/DestinatarioExpedienteDigital/${data.ClienteDestinatarioExpedienteDigitalId}`, data)
      .then(response => {
        throwSuccess(response)
        resolve(response)
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const fetchCustomerRecipient = (id, callback) => {
    axios
      .get(`${API_URL}/Clientes/DestinatarioExpedienteDigital/${id}`)
      .then(response => {
        callback(response)
      })
      .catch(error => {
        throwError(error)
      })
  }
  // const createCustomerRecipients = (data, callback) => defaultMethods.create('DestinatarioExpedienteDigital', {}, true, data, callback)
  const fetchCustomerRecipients = (data, callback) => defaultMethods.fetch('DestinatarioExpedienteDigital', {}, data, callback)
  const deleteCustomerRecipients = (id, callback) => defaultMethods.remove('DestinatarioExpedienteDigital/%{id}', { id }, true, callback)
  // const deleteCustomerRecipients = id => new Promise((resolve, reject) => {
  //   axios
  //     .delete(`${API_URL}/Clientes/DestinatarioExpedienteDigital/${id}`)
  //     .then(response => {
  //       throwSuccess(response)
  //       resolve(response)
  //     })
  //     .catch(error => {
  //       reject(error)
  //       throwError(error)
  //     })
  // })

  return {
    fetchCustomers,
    createCustomer,
    fetchCustomer,
    updateCustomer,
    deleteCustomer,
    fetchRelatedCustomers,
    createRelatedCustomer,
    fetchRelatedCustomer,
    updateRelatedCustomer,
    deleteRelatedCustomer,
    fetchCustomerRecipients,
    fetchCustomerRecipient,
    createCustomerRecipients,
    deleteCustomerRecipients,
    updateCustomerRecipient,
  }
}
