<template>
  <div>
    <b-card>
      <b-card-header>
        <h3 class="mb-2">
          {{
            CustomerRecipient.clienteDestinatarioId == 0
              ? $t("AppCustomer.CustomerRecipients.newRecipients")
              : $t("AppCustomer.CustomerRecipients.newRecipients")
          }}
        </h3>
      </b-card-header>
      <b-card-body>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onAddCustomerRecipients)"
          >
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  :label="$t('AppCustomer.CustomerRecipients.columns.fullName')"
                  label-for="alias"
                >
                  <validation-provider
                    #default="validationContext"
                    rules="required|min:3"
                    :name="$t('AppCustomer.CustomerRecipients.columns.fullName')"
                  >
                    <b-form-input
                      v-model="CustomerRecipient.name"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('AppCustomer.CustomerRecipients.columns.alias')"
                  rules="required|alpha-num|min:2|max:50"
                >
                  <b-form-group
                    :label="$t('AppCustomer.CustomerRecipients.columns.alias')"
                    label-for="alias"
                  >
                    <b-form-input
                      v-model="CustomerRecipient.alias"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('AppCustomer.CustomerRecipients.columns.email')"
                  rules="required|email"
                >
                  <b-form-group
                    :label="$t('AppCustomer.CustomerRecipients.columns.email')"
                    label-for="alias"
                  >
                    <b-form-input
                      v-model="CustomerRecipient.email"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('AppCustomer.CustomerRecipients.columns.language')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('AppCustomer.CustomerRecipients.columns.language')"
                    label-for="alias"
                  >
                    <v-select
                      v-model="CustomerRecipient.lang"
                      label="text"
                      :options="language"
                      :reduce="option => option.value"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row
              class="float-right mt-2 mb-2"
              md="3"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mr-2"
                variant="primary"
                :disabled="CustomerRecipient.name === '' || CustomerRecipient.alias === '' || CustomerRecipient.email === '' || CustomerRecipient.lang === null"
                type="submit"
              >
                {{ $t('Lists.Add') }}
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import router from '@/router'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { onMounted, ref } from '@vue/composition-api'
import customerService from '@/services/customer.service'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BRow,
    BCol,
    vSelect,
    BButton,

    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup() {
    const {
      createCustomerRecipients,
    } = customerService()
    const blankCustomerRecipient = {
      clienteDestinatarioId: 0,
      name: '',
      alias: '',
      email: '',
      lang: null,
    }
    const CustomerRecipient = ref(JSON.parse(JSON.stringify(blankCustomerRecipient)))
    const resetCustomerRecipients = () => {
      CustomerRecipient.value = JSON.parse(JSON.stringify(blankCustomerRecipient))
    }
    const language = ref([
      { text: 'Español', value: 1 },
      { text: 'English', value: 2 },
    ])
    const onAddCustomerRecipients = () => {
      createCustomerRecipients({
        ClienteId: router.currentRoute.params.clienteId,
        NombreCompleto: CustomerRecipient.value.name,
        Alias: CustomerRecipient.value.alias,
        Correo: CustomerRecipient.value.email,
        LocalizacionIdiomaId: CustomerRecipient.value.lang,
      })
        .then(() => {
          router.push({ name: 'apps-customers-edit', params: router.currentRoute.params.clienteId })
        })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCustomerRecipients)
    onMounted(() => {
      resetCustomerRecipients()
      resetForm()
    })
    return {
      refFormObserver,
      getValidationState,
      resetForm,
      language,
      CustomerRecipient,
      onAddCustomerRecipients,
    }
  },

}
</script>
